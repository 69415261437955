import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/semantic-hmtl",
  "date": "2014-05-11",
  "title": "SEMANTIC HTML",
  "author": "admin",
  "tags": ["development", "html"],
  "featuredImage": "feature.jpg",
  "excerpt": "Semantic HTML is a way of describing the content of an HTML document, it is not a way of making your page look pretty or visually appealing."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Semantic code encourages web developer/designers to write code that describes the content rather than how that content should look. For example, if you code the title of a page like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div id='page-title'>This is the page title</div>
`}</code></pre>
    <p>{`This would make the title stand out, and you could use the id to give it a more striking appearance, giving it the appearance of a page title, but this means a browser is unable to identify this as being the page title and the search engines will not be able to either .
Ideally, to write the same title semantically so that a browser will understand that this is a title, you would replace the above with the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<h1>This is a heading</h1>
`}</code></pre>
    <p>{`The job of dealing with the appearance of your heading is the responsibility of the CSS.
I have already hinted at one reason why semantic code is important when I said that without explaining what a piece of content is, a browser has no way of identifying it. The ability for a browser to be able to understand your content is important for a number of reasons, which I will explain in the topics below.`}</p>
    <h2>{`Accessibility`}</h2>
    <p>{`Many visually impaired people rely on speech browsers to read pages back to them. These programs cannot interpret pages very well unless they are clearly explained.`}</p>
    <h2>{`Search Engines`}</h2>
    <p>{`Search engines need to understand what your content is about in order to rank you properly on search engines. Semantic code tends to improve your placement on search engines, as it is easier for the "search engine spiders" to understand.`}</p>
    <h2>{`Faster to download`}</h2>
    <p>{`As you can see from the example above, semantic code is shorter and so downloads faster.`}</p>
    <h2>{`Easy to change the look & feel`}</h2>
    <p>{`Semantic code makes applying themes for sites easier because you can apply design style to headings across an entire site instead of on a per page basis. Because semantic code does not contain design elements it is possible to change the look and feel of your site without recoding all of the HTML. Once again, because design is held separately from your content, semantic code allows anybody to add or edit pages without having to have a good eye for design. You simply describe the content and the cascading style sheet defines what that content looks like.`}</p>
    <h2>{`Easy to read`}</h2>
    <p>{`Semantic code is easier for people to understand too so if a new web designer picks up the code they can learn it much faster.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      